import React, { useEffect, useState, useMemo, type MouseEvent, type FC } from 'react'
import { useLocation } from 'react-router-dom';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Avatar } from '@mui/material';
import { t } from 'i18next';

import { FabComponent } from '../FabComponent/FabComponent'
import { AddFundsIcon } from '../icons/AddFundsIcon'
import { useTheme } from '../ThemeProvider/ThemeProvider';
import { TransactionModalContext, MenuSettingsComponent, useSnackbar, type PositionType } from '../../components';
import { DepositComponent } from '../../pages/Accounts/DepositSection/DepositComponent'
import { getAccounts as getAllAccounts } from '../../redux/reducers/accounts';
import { getTotalBalance, getTotalCredit } from '../../redux/reducers/cfd';
import { getHeaderCurrencyRates } from '../../redux/reducers/currencyRates';
import { useAppSelector, useAppDispatch } from '../../hooks/redux'
import { toFixed, getCurrencySymbol } from '../../helpers/util';
import { selectUserProfileImage } from '../../redux/selectors/user';
import { socket } from '../../web/socket'
import { AuthData } from '../../auth/AuthWrapper';
import { type SetStateAction } from '../PasswordInputComponent/PasswordInputComponent';
import { LogoIcon } from '../icons/LogoIcon';
import { useWindowSize } from '../../hooks/useWindowSize';
import { useBodyOverflow } from '../../hooks/useBodyOverflow';
import { selectIsAlertMessage, selectIsRunningAdvertisement } from '../../redux/selectors/alertMessage';

import style from './Header.module.css'

interface InterfaceHeader {
  openMenu: boolean
  setOpenMenu: (el: SetStateAction<boolean>) => void
}

interface IAcountInfoValue {
  label: string
  value: string
  style?: Record<string, string>
}

const visibilityIconSize = { fontSize: 16 }

export const Header: FC<InterfaceHeader> = ({ openMenu, setOpenMenu }) => {
  const dispatch = useAppDispatch();
  const [widthScreen] = useWindowSize();
  const { handleOpen } = useSnackbar();
  const { logout } = AuthData();
  const [openDeposit, setOpenDeposit] = useState<boolean>(false);
  const [isBalanceVisible, setIsBalanceVisible] = useState<boolean>(true);
  const [pnl, setPnl] = useState<number>(0);
  const { theme } = useTheme();
  const { _id: userId, firstName, lastName, brand } = useAppSelector((state) => state.user);
  const accounts = useAppSelector((state) => state.accounts);
  const profileImage: string | null = useAppSelector(selectUserProfileImage);
  const isAlertMessage = useAppSelector(selectIsAlertMessage);
  const isRunningAdvertisement = useAppSelector(selectIsRunningAdvertisement);
  const { uniqueId } = useAppSelector((state) => state.cfd);
  const { headerRates } = useAppSelector((state) => state.currencyRates);
  const location = useLocation();
  const isAlertMessageShown = (isAlertMessage && location.pathname.includes('/trade'));
  const isRunningAdvertisementShown = (isRunningAdvertisement && location.pathname.includes('/trade'))
  useBodyOverflow(openDeposit);

  const imageLink = `${process.env.REACT_APP_IMAGES_URL}${profileImage}`

  const selectedAccount = useMemo(() => (
    accounts.find((account) => account.isActive) ?? null
  ), [accounts]);

  const equity = (selectedAccount?.balance ?? 0) + pnl + (selectedAccount?.credit ?? 0);

  const symbolIcon = useMemo(() => (getCurrencySymbol(selectedAccount?.cfdAccountCurrency.symbol ?? '')), [selectedAccount])

  useEffect(() => {
    dispatch(getHeaderCurrencyRates());
  }, []);

  useEffect(() => {
    if (userId !== undefined) {
      const isSessionToken = sessionStorage.getItem('token');

      socket.emit('startStreaming', { userId, justSubscribe: isSessionToken });

      socket.on(`onLogoutUser&${userId}`, () => { logout(userId); });
      socket.on(`reciveTransactionNotification&${userId}`, ({ type, message }) => {
        const openParams = {
          message,
          header: `The ${type} success!`,
          modalType: 'transaction',
          position: { vertical: 'bottom', horizontal: 'left' } satisfies PositionType,
          actionText: '',
          autoClose: false
        }

        handleOpen(openParams);

        Promise.allSettled([
          dispatch(getAllAccounts(userId)),
          dispatch(getTotalBalance(userId)),
          dispatch(getTotalCredit(userId))
        ])
      });

      Promise.allSettled([
        dispatch(getAllAccounts(userId)),
        dispatch(getTotalBalance(userId)),
        dispatch(getTotalCredit(userId))
      ])
    }

    return () => {
      if (userId !== undefined) {
        socket.off(`reciveTransactionNotification&${userId}`);
        socket.off(`onLogoutUser&${userId}`)
      }
    }
  }, [userId])

  useEffect(() => {
    if (!userId || !selectedAccount) {
      return;
    }

    const accountId = selectedAccount._id;
    const symbol = selectedAccount.cfdAccountCurrency.symbol
    socket.emit('getActivePnL', { userId, uniqueId, accountId });
    socket.emit('getActiveMargin', { userId, uniqueId, accountId });

    socket.on(`reciveActivePnL&${uniqueId}&${accountId}`, (pnl) => {
      const convertedPnl = Number(pnl) * (headerRates?.[symbol] ?? 1);

      setPnl(convertedPnl);
    });

    return () => {
      socket.off(`reciveActivePnL&${uniqueId}&${selectedAccount._id}`);
      socket.off(`reciveActiveMargin&${uniqueId}&${selectedAccount._id}`);
      socket.emit('removeActivePnLListener');
      socket.emit('removeActiveMarginListener');
      setPnl(0);
    }
  }, [uniqueId, userId, selectedAccount])

  const depositFundsBtn = (): void => {
    setOpenDeposit(true);
  }

  const settingsBtn = (): void => {
    setOpenMenu((prev) => !prev);
  }

  const balanceVisibility = (event: MouseEvent<HTMLButtonElement>): void => {
    setIsBalanceVisible((prevState: boolean) => !prevState)
  }

  const accountInfoValue: IAcountInfoValue[] = [
    { label: t('header.labels.credit'), value: `${symbolIcon} ${toFixed(selectedAccount?.credit ?? 0, 2)}` },
    { label: t('header.labels.equity'), value: `${symbolIcon} ${toFixed(equity, 2)}` },
    {
      label: t('header.labels.pnl'),
      value: `${symbolIcon} ${toFixed(pnl, 2)}`,
      style: { color: pnl >= 0 ? 'var(--CFD-theme-Custom-Green)' : 'var(--CFD-theme-Custom-Red)' }
    }
  ]

  const TransactionModal = useMemo(() => {
    return (
      <TransactionModalContext
        open={ openDeposit }
        setOpen={ setOpenDeposit }
        component={
          <DepositComponent setOpen={ setOpenDeposit } selectedAccount={ selectedAccount } />
        }
      />
    )
  }, [openDeposit, selectedAccount]);

  const MenuSetting = useMemo(() => {
    return openMenu && <MenuSettingsComponent setOpenMenu={ setOpenMenu } />
  }, [openMenu])

  return (
    <>
      <div
        className={`${style.wrapper} ${style[`wrapper-${theme}`]}`}
        style={{
          height: (isAlertMessageShown || isRunningAdvertisementShown) ? '64px' : '72px',
          position: (isAlertMessageShown || isRunningAdvertisementShown) ? 'absolute' : 'fixed'
        }}
      >
        <div className={ style.headerLiveMobileLogo }>
          {
            ((brand?.landingUrl) != null)
              ? <a href={ brand?.landingUrl } target="_blank" rel="noreferrer"><LogoIcon className={style.logo}/></a>
              : <LogoIcon className={style.logo}/>
          }
        </div>

        <div className={ style.headerLiveMobileOptions }>
          <div className={ style.headerLiveMobileBtn }>
            <FabComponent variant="extended" size='small' onClick={ depositFundsBtn }>
              <AddFundsIcon color='var(--CFD-theme-System-Primary)'/>
              <span className={style.headerTitle}>{t('header.deposit_button_title')}</span>
            </FabComponent>
          </div>

          <div className={style['header-menu-wrapper']}>
            <div className={ style.accountInfoWrapper }>
              <div className={ style.accountInfoBallanceWrapper }>
                <div className={ style.accountInfoBallance }>
                  <p className={ style.accountInfoBallanceText }>{t('labels.balance')}</p>
                  { isBalanceVisible
                    ? <button onClick={ balanceVisibility } className={ style.accountInfoBallanceBtn }>
                        <VisibilityIcon style={visibilityIconSize}/>
                      </button>
                    : <button onClick={ balanceVisibility } className={ style.accountInfoBallanceBtn }>
                        <VisibilityOffIcon style={visibilityIconSize}/>
                      </button>
                  }
                </div>
                <p className={ style.accountInfoBallanceAmount }>
                  {
                    isBalanceVisible && <>
                        {`${symbolIcon} ${toFixed(selectedAccount?.balance ?? 0, 2)}`}
                      </>
                  }
                </p>
              </div>
              {
                accountInfoValue.map((item, index) => (
                  <div className={ style.accountInfo } key={ index }>
                    <p className={ style.accountInfoTitle }>{ item.label }</p>
                    <p className={ style.accountInfoAmount } style={ item.style }>{ item.value }</p>
                  </div>
                ))
              }
            </div>
            <div className={style['header-user-wrapper']} onClick={ settingsBtn }>
              <div className={style['user-info-wrapper']}>
                <span className={style['user-info-name']}>{`${firstName} ${lastName}` }</span>
              </div>
              {
                profileImage === null
                  ? firstName !== undefined && firstName.length > 0 && (
                      <div className={style.userIcon}>
                        {firstName[0]}
                      </div>
                  )
                  : <Avatar sx={{ width: 40, height: 40 }} alt={firstName} src={imageLink} />
              }
            </div>
          </div>
        </div>

      </div>
      { MenuSetting }
      { TransactionModal }
   </>
  )
}
